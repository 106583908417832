export const environment = {
  production: true,

  PORTAL_URI: `https://sales.malitamalta.com/`,
  API_ENDPOINT: 'https://sales-system-api.malitamalta.com/',
  API_GRAPHQL: `https://sales-system-api.malitamalta.com/api/graphql`,

  AUTHORITY_ENDPOINT: 'https://authentication.malitamalta.com',

  POST_LOGOUT_REDIRECT_URI: 'https://sales.malitamalta.com/',
  REDIRECT_URI: 'https://sales.malitamalta.com/auth',
  SILENT_REDIRECT_URI: 'https://sales.malitamalta.com/silent',

  REAP_URI: 'https://projects-api.malitamalta.com',

  CLIENT_ID: "crmsalesweb",
  SCOPE: "openid profile crmsalesapi.web role orchestrate.web",

  CENTRAL : `https://scenic.malitamalta.com/`,
};
